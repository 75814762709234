<template>
  <div class="d-flex flex-column flex-md-row justify-space-between">
    <div class="ma-2 w-30-custom">
      <BarraLateralComponent
        :items="items.visaoGeral.geral"
      />
    </div>

    <div class="ma-2 w-full-custom">
      <v-card elevation="0" class="pa-4 d-flex flex-column">
        <div class="d-flex flex-column flex-md-row justify-space-between">
          <!-- Ranking -->
          <v-card
            width="100%"
            elevation="0"
            style="background-color: white !important"
            class="white pa-4 ma-md-2 my-2"
          >
            <h1>Ranking</h1>

            <div class="d-flex flex-row align-center justify-center">
              <h2 class="font-weight-bold font-size-person mr-2">
                {{ items.visaoGeral.ranking }}º
              </h2>
              <span class="icon-person">🏆</span>
            </div>
          </v-card>

          <!-- Nine box -->
          <v-card
            width="100%"
            elevation="0"
            :disabled="items && !items.visaoGeral.ninebox"
            style="background-color: white !important"
            class="white pa-4 ma-md-2 my-2"
          >
            <h1>Posição nine box</h1>

            <div class="d-flex flex-row align-center justify-center">
              <h2 class="font-weight-bold mr-2">
                {{ items.visaoGeral.ninebox }}
              </h2>
              <span class="icon-person">📈</span>
            </div>
          </v-card>
        </div>

        <!-- Gráfico -->
        <div class="white pa-2 ma-2">
          <VueApexCharts
            type="area"
            height="350"
            :options="chartOptions"
            :series="items.visaoGeral.series"
          ></VueApexCharts>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import BarraLateralComponent from "./components/BarraLateralComponent.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "VisaoGeral",
  components: {
    BarraLateralComponent,
    VueApexCharts,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Evolução de avaliações",
          align: "left",
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "month",
          categories: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
        },
      },
    };
  },

  computed: {
    items: {
      get() {
        return this.$store.getters[
          "avd/getVisualizarAvaliacao"
        ];
      },
    },
  },
};
</script>

<style scoped>
.icon-person {
  font-size: 60pt;
  color: yellow;
}

.font-size-person {
  font-size: 27pt;
}

.w-full-custom {
  width: 100%;
}

.w-30-custom {
  width: 30%;
}

@media (max-width: 959px) {
  .w-30-custom {
    width: 100%;
  }
}
</style>
